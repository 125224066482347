import { Select as AntSelect, Tooltip } from 'antd';
import { FilterFunc } from 'rc-select/lib/Select';
import { CSSProperties, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { QuestionMarkSvg } from '../../../assets/icons';
import theme from '../../../assets/theme';

const { Option } = AntSelect;

type Props = {
  label?: string;
  fluid?: boolean;
  placeholder?: string;
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  onSelect?: (value: string | number | boolean) => void;
  options: { displayName: string; value: string | number | boolean; icon?: ReactNode }[];
  value?: string | number | boolean;
  iconValue?: ReactNode;
  disabled?: boolean;
  showSearch?: boolean;
  onSearch?: (value: string) => void;
  filterOption?: FilterFunc<{ value: string; displayName: string }>;
  optionFilterProp?: string;
  tooltipText?: () => JSX.Element | string;
  className?: string;
  noBorder?: boolean;
};
const Select = ({
  value,
  iconValue,
  onSelect,
  options,
  label,
  labelStyle,
  placeholder,
  fluid,
  style,
  disabled,
  showSearch,
  onSearch,
  filterOption,
  optionFilterProp,
  tooltipText,
  className,
  noBorder = false,
}: Props) => {
  return (
    <Wrapper $fluid={fluid} style={style} $noBorder={noBorder}>
      {label && (
        <Label style={labelStyle ? labelStyle : {}}>
          {label}
          {tooltipText && (
            <Tooltip title={tooltipText}>
              <HelpIconWrapper>
                <QuestionMarkSvg />
              </HelpIconWrapper>
            </Tooltip>
          )}
        </Label>
      )}
      <AntSelect
        value={value}
        disabled={disabled}
        onSelect={(_: unknown, o: { value: string | number | boolean }) =>
          onSelect && onSelect(o.value)
        }
        suffixIcon={iconValue}
        className={className}
        style={{ cursor: 'pointer' }}
        showSearch={showSearch}
        onSearch={onSearch}
        filterOption={filterOption}
        optionFilterProp={optionFilterProp}
        placeholder={placeholder}
      >
        {options.map(opt => (
          <Option
            key={opt.value === true ? 'true' : opt.value === false ? 'false' : opt.value}
            value={opt.value}
            style={{ display: 'flex' }}
          >
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {opt.displayName}
              {opt.icon && opt.value !== value && (
                <span style={{ marginRight: 8, padding: 0 }}>{opt.icon}</span>
              )}
            </div>
          </Option>
        ))}
      </AntSelect>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $fluid?: boolean; $noBorder?: boolean }>`
  cursor: pointer;
  .ant-select {
    width: 100%;
  }
  &&& .ant-select .ant-select-selector {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    height: 42px;
    display: flex;
    align-items: center;
    font-size: 16px;
    ${props =>
      props.$fluid &&
      css`
        width: 100%;
      `}
    ${props =>
      props.$noBorder &&
      css`
        border: 1px solid transparent;
        box-shadow: ${theme.effects.magicFloatingShadow};
      `}
  }
  .ant-select-selection-item {
    height: 100%;
    align-items: center;
    display: flex;
  }

  .ant-select-selection-search {
    margin-top: 5px;
  }
  ${props =>
    props.$fluid &&
    css`
      height: 100%;
    `}
`;

const Label = styled.div`
  color: #a5a5a5;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
`;

const HelpIconWrapper = styled.span`
  cursor: pointer;
  margin-left: 5px;
  height: 16px;
  display: inline-block;
  svg {
    height: 16px;
    width: 16px;
  }
`;

export default Select;

import { LoadingOutlined } from '@ant-design/icons';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import { Alert, Spin } from 'antd';
import { motion, useAnimation } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';

import CuriRocketSvg from '../assets/icons/CuriRocketSvg';
import EmojiThanks from '../assets/icons/EmojiThanks';
import HeartEmojiSvg from '../assets/icons/HeartEmojiSvg';
import Logo from '../assets/icons/Logo';
import StarStruckSvg from '../assets/icons/StarStruckSvg';
import theme from '../assets/theme';
import { Input } from '../components/_atoms/_Input';
import { Loader } from '../components/_atoms/_Loader';
import Select from '../components/_atoms/_Select/Select';
import { Button } from '../components/_atoms/Button';
import { IconWrapper } from '../components/_atoms/IconWrapper';
import BaseModal from '../components/_molecules/BaseModal';
import {
  CompareAllFeaturesTable,
  PricingPlans,
} from '../components/_organisms/PricingPageContent/PricingPageContent';
import { GenericCheckout } from '../components/payments/GenericCheckout';
import Metrics from '../services/metrics';
import metrics from '../services/metrics';
import { CenteredContainer } from '../styles/CenteredContainer';
import { billingCountries, getCurrencyCharacter } from '../utils/billingUtils';
import { TenantLicense } from '../utils/tenantPlanUtils';
import { useBreakPoint } from './useBreakPoint';
import useCustomer from './useCustomer';
import useMe from './useMe';
import { useMetrics } from './useMetrics';
import usePrices from './usePrices';
import useRouterMatch from './useRouterMatch';
import useSubmitFeedback from './useSubmitFeedback';
import useTenant, { IndexedTenant } from './useTenant';
import useTenantUsers from './useTenantUsers';

export const CountrySelector = ({
  onChange,
  value,
  style,
  labelStyle,
}: {
  value?: string;
  onChange: (val: string) => void;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
}) => {
  const options = billingCountries.map(c => ({ displayName: c.name, value: c.code }));
  const { t } = useTranslation('Host');
  return (
    <Select
      style={style}
      label={t('Select country')}
      showSearch
      value={value}
      options={options}
      placeholder="Select country"
      labelStyle={labelStyle}
      optionFilterProp="displayName"
      onSelect={val => onChange(val as string)}
      filterOption={(input: string, option?: { displayName: string; value: string }) => {
        const matchOption = options.find(o => o.value === option?.value);
        if (matchOption) {
          return (
            matchOption.value.toLowerCase().includes(input.toLowerCase()) ||
            matchOption.displayName.toLowerCase().includes(input.toLowerCase())
          );
        }
        return false;
      }}
    />
  );
};

const PaywallMessage = ({
  onUpgradeClicked,
  isAdmin,
  onUpgradeEnterprise,
}: {
  isOnFreePlan: boolean;
  onUpgradeClicked: () => void;
  isBusiness: boolean;
  isWorkspacePaywall: boolean;
  isAdmin: boolean;
  onUpgradeEnterprise: (enterpriseType: 'enterprise' | 'schoolSite') => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation('Host');

  return (
    <>
      <CenteredContainer style={{ flexDirection: 'column' }}>
        <IconWrapper height="150px" width="150px">
          <EmojiThanks />
        </IconWrapper>
        <p
          style={{
            marginTop: 20,
            fontWeight: 'bold',
            width: '100%',
            fontSize: 28,
            textAlign: 'center',
          }}
        >
          {t("Oops, you've reached the limit ")}
        </p>

        <ul
          style={{
            marginTop: 20,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 40,
          }}
        >
          <p
            style={{
              fontWeight: 'bold',
              marginBottom: 0,
              width: '100%',
              textAlign: 'center',
              fontSize: 16,
            }}
          >
            {'Our free plan includes:'}
          </p>
          <li style={{ fontWeight: 'bold', fontSize: 16 }}>
            {t('20 monthly slide translator credits')}
          </li>
        </ul>
      </CenteredContainer>
      <CenteredContainer>
        {!isAdmin && (
          <p style={{ fontWeight: 'bold' }}>
            {t('Only an admin can upgrade to premium. You can ask your admin, or')}{' '}
            <span
              onClick={() => onUpgradeEnterprise('schoolSite')}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                width: '100%',
                textAlign: 'center',
              }}
            >
              {t('contact us')}
            </span>{' '}
            {t('to upgrade to a school license')}
          </p>
        )}
        {isAdmin && (
          <Button
            height="42px"
            type="primary"
            onClick={() => {
              onUpgradeClicked();
            }}
          >
            {t('Upgrade now for unlimited use')}
          </Button>
        )}
      </CenteredContainer>
    </>
  );
};

const PaywallHandler = ({
  onUpgradeClicked,
  onUpgradEnterprise,
  isWorkspacePaywall,
  onClose,
}: {
  onUpgradeClicked: () => void;
  onUpgradEnterprise: (enterpriseType: 'enterprise' | 'schoolSite') => void;
  isWorkspacePaywall: boolean;
  onClose: () => void;
}) => {
  const { data } = useTenant();
  const { me } = useMe();
  const { trackEvent } = useIntercom();

  const isOnFreePlan = data && !data.isCuripodPremium && !data.isCuripodEnterprise;
  useEffect(() => {
    trackEvent('paywallPompted', { isWorkspacePaywall: isWorkspacePaywall });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <PaywallMessage
        isOnFreePlan={!!isOnFreePlan}
        onUpgradeClicked={onUpgradeClicked}
        isWorkspacePaywall={isWorkspacePaywall}
        isBusiness={me?.accountType === 'professional'}
        isAdmin={me?.role === 'admin' || me?.role === 'superAdmin'}
        onUpgradeEnterprise={onUpgradEnterprise}
        onClose={onClose}
      />
    </>
  );
};

const AnimatedProductItemContainer = ({
  borderColor,
  onClick,
  children,
}: {
  borderColor: string;
  children?: React.ReactNode;
  onClick?: () => void;
}) => {
  const animation = useAnimation();

  const hoverSequence = async () => {
    await animation.start({ scale: 1.03, transition: { duration: 0.1 } });
    animation.start({ scale: 1, transition: { duration: 0.1 } });
  };
  return (
    <ProductItemContainer
      animate={animation}
      onHoverStart={hoverSequence}
      onClick={() => onClick && onClick()}
      $borderColor={borderColor}
    >
      {children}
    </ProductItemContainer>
  );
};

const ScrollingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  max-height: 80vh;
  padding: 5px;
  border-radius: 10px;
`;

const ProductSelector = ({
  onProductSelected,
  isWorkspacePaywall,
}: {
  onProductSelected: (product: 'free' | 'premium' | 'schoolSite' | 'enterprise') => void;
  isWorkspacePaywall: boolean;
}) => {
  const { prices } = usePrices();

  if (!prices?.prices) return null;

  return (
    <>
      <PricingPlans
        prices={prices.prices}
        isWorkspacePaywall={isWorkspacePaywall}
        onProductSelected={onProductSelected}
        isLandingPage={false}
      />
      <CompareAllFeaturesTable />
    </>
  );
};

const SmallDisclaimer = styled.p`
  font-size: 12px;
  color: ${theme.colors.iconGrey};
  margin-top: 10px;
  max-width: 800px;
  margin: 0px auto 10px auto;
  padding: 0 20px;
`;

const ProductItemContainer = styled(motion.div)<{ $borderColor: string }>`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  border-radius: 10px;
  border-left: 4px solid ${({ $borderColor }) => $borderColor};
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
  background: white;
  color: ${theme.colors.black};

  cursor: pointer;
  li,
  p {
    font-size: 18px;
    font-weight: bold;
  }

  @media (max-width: 750px) {
    width: 100%;
  }
`;
const ProductsContainer = styled.div`
  display: flex;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
  & > *:not(:first-child) {
    margin-left: 10px;
  }

  @media (max-width: 750px) {
    flex-direction: column;
    width: 100%;
    & > *:not(:last-child) {
      margin-right: 0px;
      margin-bottom: 10px;
    }
    & > *:not(:first-child) {
      margin-left: 0px;
      margin-top: 10px;
    }
  }
`;

const getPreferredCurrency = (country?: string) => {
  if (!country) return 'eur';
  if (country === 'US') return 'usd';
  if (country === 'NO') return 'nok';
  return 'eur';
};

const BillingCycleSelector = ({
  onBillingCycleSelected,
  selectedCountry,
  isBusiness,
  totalSeats,
}: {
  selectedCountry?: string;
  onBillingCycleSelected: (cycle: 'yearly' | 'monthly', country?: string) => void;
  isBusiness: boolean;
  totalSeats: number;
}) => {
  const { t } = useTranslation('Host');
  const { prices } = usePrices();
  const [localSelectedCountry, setSelectedCountry] = useState<string>();

  const yearlyPrice = prices?.prices.find(
    price =>
      price.recurring.interval === 'year' &&
      price.currency === getPreferredCurrency(localSelectedCountry),
  );
  const monthlyPrice = prices?.prices.find(
    price =>
      price.recurring.interval === 'month' &&
      price.currency === getPreferredCurrency(localSelectedCountry),
  );
  useEffect(() => {
    if (selectedCountry) {
      setSelectedCountry(selectedCountry);
    }
  }, [selectedCountry]);

  const isLargerThan750 = useBreakPoint(750);
  if (!yearlyPrice) return null;
  const currency = (yearlyPrice?.currency as string).toUpperCase();
  const seats = totalSeats;
  const yearlyPriceTotal = (yearlyPrice?.unit_amount / 100) * seats;
  const yearlyPricePerMonth = (yearlyPrice?.unit_amount / 100 / 12).toFixed(2);
  const monthlyPricePerMonth = monthlyPrice?.unit_amount / 100;
  const monthlyPriceTotal = (monthlyPrice?.unit_amount / 100) * seats;

  const handleBillingCycleSelected = (cycle: 'yearly' | 'monthly') => {
    if (localSelectedCountry) {
      onBillingCycleSelected(cycle, localSelectedCountry);
    }
  };

  return (
    <div style={{ marginTop: 20 }}>
      <CenteredContainer>
        <div style={{ width: isLargerThan750 ? 300 : '100%', marginBottom: 20 }}>
          {!selectedCountry && (
            <CountrySelector value={localSelectedCountry} onChange={setSelectedCountry} />
          )}
        </div>
      </CenteredContainer>
      {localSelectedCountry && (
        <ProductsContainer>
          <AnimatedProductItemContainer
            onClick={() => handleBillingCycleSelected('monthly')}
            borderColor={theme.colors.blue}
          >
            <h3
              style={{
                fontSize: 28,
                fontWeight: 'bold',
                width: '100%',
                textAlign: 'center',
              }}
            >
              {t('Monthly')}
            </h3>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                flex: 1,
                paddingTop: 20,
              }}
            >
              <p style={{ marginBottom: 0, width: '100%', textAlign: 'center' }}>
                {getCurrencyCharacter(currency)} {monthlyPricePerMonth} {t('per month')}{' '}
                {isBusiness && t('per presenter')}
              </p>
              <p
                style={{
                  marginTop: 20,
                  marginBottom: 0,
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {getCurrencyCharacter(currency)} {monthlyPriceTotal} {t('total due now')}
              </p>
            </div>
          </AnimatedProductItemContainer>

          <AnimatedProductItemContainer
            onClick={() => handleBillingCycleSelected('yearly')}
            borderColor={theme.colors.green}
          >
            <h3
              style={{
                fontSize: 28,
                fontWeight: 'bold',
                width: '100%',
                textAlign: 'center',
              }}
            >
              {t('Yearly')}
            </h3>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                flex: 1,
                paddingTop: 20,
              }}
            >
              <p style={{ marginBottom: 0, width: '100%', textAlign: 'center' }}>
                {getCurrencyCharacter(currency)} {yearlyPricePerMonth} {t('per month')}{' '}
                {isBusiness && t('per presenter')}
              </p>
              <p
                style={{
                  marginTop: 20,
                  marginBottom: 0,
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {getCurrencyCharacter(currency)} {yearlyPriceTotal} {t('total due now')}
              </p>
            </div>
          </AnimatedProductItemContainer>
        </ProductsContainer>
      )}
    </div>
  );
};

const CheckoutForm = ({
  currency,
  priceTotal,
  country,
}: {
  currency: string;
  priceTotal: number;
  country: string;
}) => {
  const { me } = useMe();
  const { tenantId } = useRouterMatch();
  const stripe = useStripe();
  const { trackEvent } = useIntercom();
  const elements = useElements();
  const [isConfirming, setIsConfirming] = useState(false);
  const { t } = useTranslation('Host');
  const [paymentConfirmationError, setPaymentConfirmationError] = useState<StripeError>();

  const handleSubmit = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    Metrics.getLogger().logEvent('Billing.ConfirmCheckout', {});

    if (elements == null) {
      return;
    }

    if (stripe === null) {
      return;
    }

    setIsConfirming(true);
    setPaymentConfirmationError(undefined);
    const error = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/${tenantId}/profile`,
        payment_method_data: {
          billing_details: {
            name: me?.username,
            email: me?.email,
            address: {
              country,
            },
          },
        },
      },
    });
    if (!error) {
      Metrics.getLogger().logEvent('Billing.Confirmed');
      trackEvent('Billing.Confirmed');
    }
    if (error && error.error) {
      setPaymentConfirmationError(error.error);
    }
    setIsConfirming(false);
  };

  return (
    <div style={{ width: '100%' }}>
      <PaymentElement
        options={{
          business: { name: 'Curipod' },
          terms: { card: 'never' },
          fields: {
            billingDetails: {
              address: {
                country: 'never',
              },
              phone: 'auto',
              email: 'never',
              name: 'never',
            },
          },
        }}
      />

      {paymentConfirmationError && (
        <div>
          <p style={{ color: theme.colors.red }}>
            {t('paymentfailed', {
              defaultValue:
                'Payment failed: {{message}} Please try again with another payment method.',
              message: paymentConfirmationError.message,
            })}
          </p>
        </div>
      )}

      <Button
        disabled={!stripe || !elements}
        type="primary"
        width="100%"
        loading={isConfirming}
        styles={{ marginTop: 10 }}
        height="60px"
        onClick={handleSubmit}
      >
        {t('paynow', {
          defaultValue: 'Pay {{currency}} {{priceTotal}} now',
          currency: getCurrencyCharacter(currency),
          priceTotal,
        })}
      </Button>
    </div>
  );
};

const OrderSummary = ({
  onPromoCodeSubmitted,
  latestInvoice,
  secret,
  isLoading,
  country,
  seats,
}: {
  onPromoCodeSubmitted: (promoCode: string) => void;
  latestInvoice?: {
    currency: string;
    subtotal: number;
    total: number;
    tax: number;
    total_discount_amounts: [{ amount: number }];
    discounts: { amount: number }[];
    billing_reason: string;
  };
  secret?: string;
  isLoading?: boolean;
  country: string;
  seats: number;
}) => {
  const { t } = useTranslation('Host');
  const currency = latestInvoice ? latestInvoice.currency.toUpperCase() : '';
  const subTotal = latestInvoice ? latestInvoice.subtotal / 100 : 0;
  const priceTotal = latestInvoice ? latestInvoice.total / 100 : 0;
  const [discountSubmitted, setDiscountSubmitted] = useState(false);
  const taxTotal = latestInvoice ? latestInvoice.tax / 100 : 0;
  const discountTotal = latestInvoice
    ? latestInvoice.total_discount_amounts.reduce(
        (acc: number, cur: { amount: number }) => (acc - cur.amount / 100) as number,
        0,
      )
    : 0;

  const [showPromoCodeInput, setShowPromoCodeInput] = useState(false);
  const [promoCode, setPromoCode] = useState<string>();

  const handlePromoCodeAdded = () => {
    setDiscountSubmitted(true);
    if (promoCode) {
      metrics.getLogger().logEvent('Billing.Checkout.Promocode.Added');
      onPromoCodeSubmitted(promoCode);
    }
  };

  const billingDate =
    latestInvoice && latestInvoice.billing_reason === 'upcoming'
      ? t('Due next period')
      : t('Due now');

  return (
    <OrderSummaryContainer>
      <Body>
        <BodyRow>
          <NormalText>&quot;Curipod Premium&quot; x {seats} seats</NormalText>
          <NormalText>
            {isLoading ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
            ) : (
              <>
                {getCurrencyCharacter(currency)} {subTotal}
              </>
            )}
          </NormalText>
        </BodyRow>
        <Line />
        <BodyRow>
          <NormalText>{t('Tax')}</NormalText>
          <NormalText>
            {isLoading ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
            ) : (
              <>
                {getCurrencyCharacter(currency)} {taxTotal}
              </>
            )}
          </NormalText>
        </BodyRow>

        {latestInvoice && latestInvoice.discounts.length > 0 && (
          <BodyRow>
            <NormalText>{t('DISCOUNTS')}</NormalText>
            <NormalText>
              {isLoading ? (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
              ) : (
                <>
                  {getCurrencyCharacter(currency)} {discountTotal}
                </>
              )}
            </NormalText>
          </BodyRow>
        )}

        <Line />
        <BodyRow>
          <LargeText>{billingDate}</LargeText>
          <LargeText>
            {isLoading ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
            ) : (
              <>
                {getCurrencyCharacter(currency)} {priceTotal}
              </>
            )}
          </LargeText>
        </BodyRow>
        {!showPromoCodeInput && (
          <PromotionCode onClick={() => setShowPromoCodeInput(true)}>
            {t('Discount or promotion code?')}
          </PromotionCode>
        )}
        {latestInvoice?.discounts.length === 0 && (
          <BodyRow>
            {showPromoCodeInput && (
              <div>
                <PromoCodeText>{t('Promotion code')}</PromoCodeText>

                <div
                  style={{
                    display: 'grid',
                    gridAutoFlow: 'column',
                    gap: '5px',
                    marginBottom: '5px',
                    alignItems: 'end',
                  }}
                >
                  <Input
                    autoFocus
                    placeholder="CURIPODISAWESOME"
                    style={{ marginTop: 10 }}
                    value={promoCode}
                    onChange={e => setPromoCode(e.target.value)}
                  />

                  <Button
                    styles={{
                      padding: '5px 5px',
                      marginTop: '5px',
                      height: '42px',
                    }}
                    disabled={!promoCode}
                    type={'ghost'}
                    onClick={handlePromoCodeAdded}
                  >
                    {t('Redeem code')}
                  </Button>
                </div>
                {discountSubmitted && promoCode && (
                  <Alert
                    type="info"
                    message={t('Invalid')}
                    description={t('Is your discount code for a another payment plan?')}
                  />
                )}
              </div>
            )}
          </BodyRow>
        )}

        {secret && (
          <BodyRow style={{ marginTop: 40 }}>
            <GenericCheckout secret={secret}>
              <CheckoutForm
                currency={currency}
                priceTotal={priceTotal}
                country={country}
              />
            </GenericCheckout>
          </BodyRow>
        )}
        <p>
          {t('By upgrading, you agree to our')}{' '}
          <Link href="/terms-of-use" passHref>
            <a
              style={{ textDecoration: 'underline' }}
              target="__blank"
              rel="noopener noreferrer"
            >
              {t('terms of use')}
            </a>
          </Link>{' '}
          {t('and policies.')}
        </p>
      </Body>
    </OrderSummaryContainer>
  );
};

const PromoCodeText = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const PromotionCode = styled(PromoCodeText)`
  background-color: ${theme.colors.blueLight};
  &:hover {
    opacity: 0.8;
  }
  padding: 5px 10px;
  border-radius: 10px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
`;

const NormalText = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

const LargeText = styled(NormalText)`
  font-size: 24px;
`;

const BodyRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #d9d9d9;
  border-radius: 20px;
`;

const Body = styled.div`
  padding: 30px 40px;

  font-weight: bold;

  p {
    margin-bottom: 0px;
  }

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
  & > *:not(:first-child) {
    margin-top: 20px;
  }

  @media (max-width: 750px) {
    padding: 30px 0px;
  }
`;

const OrderSummaryContainer = styled.div`
  width: 100%;
  border-radius: 20px;
  color: ${theme.colors.black};
`;

function usePaywallPrompt({
  startState,
  onClose,
}: {
  startState?: CheckoutState;
  onClose?: () => void;
}) {
  const router = useRouter();
  const { data: tenant, tenantPlan } = useTenant() as {
    data: IndexedTenant | undefined;
    tenantPlan: TenantLicense | undefined;
  }; // waiting for useTenantUsers from workspace to bill per seat on business plans
  const { users } = useTenantUsers();

  const { isBusiness } = useMe();
  const totalSeats = users?.length || 1;
  const { prices } = usePrices();
  const metrics = useMetrics();
  const { trackEvent } = useIntercom();
  const {
    subscribe,
    subscribeData,
    isFetchingSubscribeData,
    changeSubscription,
    changeSubscriptionSuccess,
    isChangingSubscription,
    data: customer,
    cancelSubscription,
    cancelledSubscription,
    isCancellingSubscription,
    previewChangeSubscription,
    previewChangeSubscriptionInvoice,
    isLoadingPreviewChangeSubscription,
  } = useCustomer();

  const {
    submit,
    isLoading: isSubmittingEnterpriseContact,
    isSuccess: submittedEnterpriseInterest,
  } = useSubmitFeedback();
  const { t } = useTranslation('Host');

  const isReady = tenant !== undefined;

  const isWorkspacePaywall = router.asPath.includes('workspace');
  const [checkedPromotionCode, setCheckedPromotionCode] = useState<string | undefined>();
  const shouldPromptPaywall = useCallback(() => {
    if (!tenant) return false;
    if (!tenantPlan) return false;

    if (tenantPlan.isEnterprise()) {
      return false;
    }

    if (tenantPlan.isTrial()) {
      return false;
    }

    if (tenantPlan.isPremium()) {
      return false;
    }

    if (tenantPlan.isFreemium()) {
      return false;
    }
    throw new Error('Some unexpected plan event happened');
  }, [tenant, tenantPlan]);

  const [show, setShow] = useState(false);
  const [checkoutState, setCheckoutState] = useState<CheckoutState>(
    startState || { screen: 'paywall' },
  );

  const getModalTitle = (checkoutState: CheckoutState) => {
    if (checkoutState.screen === 'paywall') return '';
    if (checkoutState.screen === 'chooseProduct') return t('Choose product');
    if (checkoutState.screen === 'chooseBillingCycle')
      return t(
        !checkoutState.country
          ? 'Choose country and billing cycle '
          : 'Choose billing cycle',
      );
    if (checkoutState.screen === 'checkout') return t('Payment details');
    if (checkoutState.screen === 'previewSubscriptionChange')
      return t('Preview plan change');
    return '';
  };
  const getModalWidth = (checkoutState: CheckoutState) => {
    if (checkoutState.screen === 'paywall') return '1000px';
    if (checkoutState.screen === 'chooseProduct') return '1000px';
    if (checkoutState.screen === 'chooseBillingCycle') return '750px';
    if (checkoutState.screen === 'checkout') return '750px';
    if (checkoutState.screen === 'subscriptionChanged') return '750px';
    if (checkoutState.screen === 'previewSubscriptionChange') return '750px';
    if (checkoutState.screen === 'freePremiumClaimed') return '750px';
    if (checkoutState.screen === 'downgradeToFree') return '750px';
    if (checkoutState.screen === 'upgradeToEnterprise') return '750px';
    return '';
  };

  const onUpgradeClicked = () => {
    setCheckoutState(state => ({ ...state, screen: 'chooseProduct' }));
  };

  const handleProductSelected = (
    product?: 'free' | 'premium' | 'schoolSite' | 'enterprise',
  ) => {
    Metrics.getLogger().logEvent('Billing.SelectProduct', {
      product,
    });
    if (product === 'enterprise') {
      setCheckoutState(state => ({ ...state, screen: 'upgradeToEnterprise' }));
    }
    if (product === 'schoolSite') {
      setCheckoutState(state => ({ ...state, screen: 'upgradeToSchoolSite' }));
    }
    if (product === 'premium') {
      setCheckoutState(state => ({
        ...state,
        screen: 'chooseBillingCycle',
        product: 'premium',
      }));
    }

    if (product === 'free') {
      setCheckoutState(state => ({ ...state, screen: 'downgradeToFree' }));
    }
  };
  const handleBillingCycleSelected = (cycle: 'yearly' | 'monthly', country?: string) => {
    Metrics.getLogger().logEvent('Billing.SelectBillingCycle', {
      cycle,
      country,
    });
    const stripeInterval = cycle === 'yearly' ? 'year' : 'month';
    const price = prices?.prices.find(
      price =>
        price.recurring.interval === stripeInterval &&
        price.currency === getPreferredCurrency(country || customer?.country),
    );
    const priceId = price?.id;
    if (!priceId) throw new Error('No price found'); //todo prevent this from happening
    if (
      customer?.subscriptionId &&
      customer.subscriptionStatus !== 'incomplete' &&
      customer.subscriptionStatus !== 'incomplete_expired'
    ) {
      previewChangeSubscription({ priceId, quantity: totalSeats });
      setCheckoutState(state => ({
        ...state,
        screen: 'previewSubscriptionChange',
        priceId,
        country,
      }));
    } else {
      // Only subscribe when you dont have an existing subscription
      if (country) {
        subscribe({ priceId, quantity: totalSeats, country });
        setCheckoutState(state => ({ ...state, screen: 'checkout', priceId, country }));
        setCheckedPromotionCode(undefined);
      }
    }
  };

  const handleCancelFreemiumDowngrade = () => {
    Metrics.getLogger().logEvent('Billing.CancelFreemiumDowngrade', {});
    setCheckoutState(state => ({ ...state, screen: 'chooseProduct' }));
  };

  const handleDowngradeToFreemium = () => {
    Metrics.getLogger().logEvent('Billing.DowngradeToFreemium', {});
    cancelSubscription();
  };

  const handlePromoCodeSubmitted = (code: string) => {
    if (checkoutState.priceId && checkedPromotionCode !== code) {
      Metrics.getLogger().logEvent('Billing.SubmittedPromoCode', {
        code: code,
      });
      setCheckoutState(state => ({ ...state, promoCode: code }));
      subscribe({
        priceId: checkoutState.priceId,
        promoCode: code,
        quantity: totalSeats,
      });
      setCheckedPromotionCode(code);
    }
  };

  const prompt = useCallback(() => {
    Metrics.getLogger().logEvent('Paywall.Prompted', {
      path: router.asPath,
      pathname: router.pathname,
    });
    setShow(true);
  }, [router.asPath, router.pathname]);
  const hide = useCallback(() => {
    setShow(false);
    setCheckoutState(state => ({ ...state, screen: 'chooseProduct' }));
    onClose && onClose();
  }, [onClose]);

  const modalTitle = getModalTitle(checkoutState);
  useEffect(() => {
    if (subscribeData?.freePremiumClaimed) {
      trackEvent('freePremiumClaimed');
      metrics.logEvent('freePremiumClaimed', { promotionCode: customer?.promoCode });
      setCheckoutState(state => ({ ...state, screen: 'freePremiumClaimed' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeData?.freePremiumClaimed]);
  useEffect(() => {
    if (customer && customer.country) {
      setCheckoutState(state => ({ ...state, country: customer.country }));
    }
  }, [customer]);

  const component = (
    <BaseModal
      width={getModalWidth(checkoutState)}
      title={modalTitle}
      visible={show}
      onCancel={hide}
      style={{ top: 10 }}
    >
      {checkoutState.screen === 'paywall' && (
        <PaywallHandler
          isWorkspacePaywall={isWorkspacePaywall}
          onUpgradeClicked={onUpgradeClicked}
          onUpgradEnterprise={(enterpriseType: 'schoolSite' | 'enterprise') =>
            handleProductSelected(enterpriseType)
          }
          onClose={hide}
        />
      )}
      {checkoutState.screen === 'chooseProduct' && (
        <ScrollingContainer>
          <ProductSelector
            isWorkspacePaywall={isWorkspacePaywall}
            onProductSelected={handleProductSelected}
          />
          {/* {(tenantPlan?.isFreemium() || tenantPlan?.isTrial()) && (
            <SmallDisclaimer>
              * For individual teachers looking to upgrade,{' '}
              <span
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => {
                  setCheckoutState(state => ({
                    ...state,
                    screen: 'chooseBillingCycle',
                  }));
                }}
              >
                click here
              </span>
              . Individual premium does not include school plan content and costs $18 per
              month.
            </SmallDisclaimer>
          )} */}
        </ScrollingContainer>
      )}
      {checkoutState.screen === 'chooseBillingCycle' && (
        <BillingCycleSelector
          selectedCountry={checkoutState.country}
          isBusiness={!!isBusiness}
          totalSeats={totalSeats}
          onBillingCycleSelected={handleBillingCycleSelected}
        />
      )}
      {checkoutState.screen === 'checkout' &&
        checkoutState.priceId &&
        checkoutState.country && (
          <OrderSummary
            onPromoCodeSubmitted={handlePromoCodeSubmitted}
            latestInvoice={subscribeData?.latest_invoice}
            isLoading={isFetchingSubscribeData}
            secret={subscribeData?.clientSecret || undefined}
            country={checkoutState.country}
            seats={totalSeats}
          />
        )}

      {checkoutState.screen === 'subscriptionChanged' && (
        <CenteredContainer style={{ flexDirection: 'column' }}>
          {isChangingSubscription && (
            <>
              <Loader />
              <p style={{ fontWeight: 'bold', fontSize: 18 }}>
                {t('Confirming subscription change')}
              </p>
            </>
          )}
          {changeSubscriptionSuccess && (
            <>
              <IconWrapper height="100px" width="100px">
                <StarStruckSvg />
              </IconWrapper>
              <p style={{ fontWeight: 'bold', fontSize: 18, textAlign: 'center' }}>
                {t('Subscription successfully changed!')}
              </p>
            </>
          )}
        </CenteredContainer>
      )}
      {checkoutState.screen === 'freePremiumClaimed' && (
        <CenteredContainer style={{ flexDirection: 'column' }}>
          <>
            <IconWrapper height="100px" width="100px">
              <CuriRocketSvg />
            </IconWrapper>
            <p style={{ fontWeight: 'bold', fontSize: 18, textAlign: 'center' }}>
              {t('You have redeemed a promotion code for free Premium!')}
            </p>
            <Button onClick={() => setShow(false)}>Lets spark more engagement!</Button>
          </>
        </CenteredContainer>
      )}
      {checkoutState.screen === 'previewSubscriptionChange' && (
        <CenteredContainer style={{ flexDirection: 'column' }}>
          {isLoadingPreviewChangeSubscription && (
            <>
              <Loader />
              <p style={{ fontWeight: 'bold', fontSize: 18 }}>
                {t('Retrieving invoice changes')}
              </p>
            </>
          )}
          {previewChangeSubscriptionInvoice && customer?.country && (
            <>
              <OrderSummary
                onPromoCodeSubmitted={handlePromoCodeSubmitted}
                latestInvoice={previewChangeSubscriptionInvoice}
                isLoading={isLoadingPreviewChangeSubscription}
                secret={undefined}
                country={customer.country}
                seats={totalSeats}
              />
            </>
          )}
          {previewChangeSubscriptionInvoice && checkoutState.priceId && (
            <>
              <Button
                loading={isChangingSubscription}
                onClick={() => {
                  if (!checkoutState?.priceId) {
                    return;
                  }
                  changeSubscription({
                    priceId: checkoutState.priceId,
                    quantity: totalSeats,
                    promoCode: checkoutState.promoCode,
                  });
                  //subscriptionChanged
                  setCheckoutState(state => ({
                    ...state,
                    screen: 'subscriptionChanged',
                  }));
                }}
              >
                {t('Confirm change')}
              </Button>
            </>
          )}
        </CenteredContainer>
      )}
      {checkoutState.screen === 'upgradeToSchoolSite' && (
        <CenteredContainer style={{ flexDirection: 'column' }}>
          {!submittedEnterpriseInterest && (
            <>
              <IconWrapper height="50px" width="100px" color={theme.colors.orange}>
                <Logo />
              </IconWrapper>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: 18,
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {t(
                  `We will happily get in touch in order to accommodate you and your school's needs`,
                )}
              </p>
              <Button
                type="primary"
                loading={isSubmittingEnterpriseContact}
                onClick={() => {
                  submit({
                    message: 'I want to upgrade to an school site licence plan',
                    accepted: true,
                  });
                }}
              >
                {t('Get offer')}
              </Button>
            </>
          )}
          {submittedEnterpriseInterest && (
            <>
              <IconWrapper height="50px" width="100px" color={theme.colors.orange}>
                <Logo />
              </IconWrapper>
              <p style={{ fontWeight: 'bold', fontSize: 18 }}>
                {t('AwesomeWeWill', 'Awesome! We will contact you shortly.')}
              </p>
              <Button
                type="primary"
                onClick={() => {
                  hide();
                }}
              >
                {t('Ok')}
              </Button>
            </>
          )}
        </CenteredContainer>
      )}
      {checkoutState.screen === 'upgradeToEnterprise' && (
        <CenteredContainer style={{ flexDirection: 'column' }}>
          {!submittedEnterpriseInterest && (
            <>
              <IconWrapper height="50px" width="100px" color={theme.colors.orange}>
                <Logo />
              </IconWrapper>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: 18,
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {t(
                  `We will happily get in touch in order to accommodate you and your district's needs`,
                )}
              </p>
              <Button
                type="primary"
                loading={isSubmittingEnterpriseContact}
                onClick={() => {
                  submit({
                    message: 'I want to upgrade to an district licence plan',
                    accepted: true,
                  });
                }}
              >
                {t('Get offer')}
              </Button>
            </>
          )}
          {submittedEnterpriseInterest && (
            <>
              <IconWrapper height="50px" width="100px" color={theme.colors.orange}>
                <Logo />
              </IconWrapper>
              <p style={{ fontWeight: 'bold', fontSize: 18 }}>
                {t('AwesomeWeWill', 'Awesome! We will contact you shortly.')}
              </p>
              <Button
                type="primary"
                onClick={() => {
                  hide();
                }}
              >
                {t('Ok')}
              </Button>
            </>
          )}
        </CenteredContainer>
      )}
      {checkoutState.screen === 'downgradeToFree' && (
        <CenteredContainer style={{ flexDirection: 'column' }}>
          {!isCancellingSubscription && !cancelledSubscription && (
            <>
              <IconWrapper height="100px" width="100px">
                <EmojiThanks />
              </IconWrapper>
              {((tenantPlan?.isEnterprise() && !tenantPlan?.isTrial()) ||
                tenantPlan?.isPremium()) && (
                <>
                  <p style={{ fontWeight: 'bold', fontSize: 18 }}>
                    {t('Are you sure you want to return to the free plan?')}
                  </p>
                  <div style={{ display: 'flex' }}>
                    <Button type="primary" onClick={handleDowngradeToFreemium}>
                      {t('Yes')}
                    </Button>
                    <Button
                      styles={{ marginLeft: 20 }}
                      type="ghost"
                      onClick={handleCancelFreemiumDowngrade}
                    >
                      {t('No')}
                    </Button>
                  </div>
                </>
              )}
              {!(
                (tenantPlan?.isEnterprise() && !tenantPlan?.isTrial()) ||
                tenantPlan?.isPremium()
              ) && (
                <>
                  <p style={{ fontWeight: 'bold', fontSize: 18 }}>
                    {t('Are you sure you want to stay on the free plan?')}
                  </p>
                  <div style={{ display: 'flex' }}>
                    <Button type="primary" onClick={hide}>
                      {t('Yes')}
                    </Button>
                    <Button
                      styles={{ marginLeft: 20 }}
                      type="ghost"
                      onClick={handleCancelFreemiumDowngrade}
                    >
                      {t('No')}
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
          {isCancellingSubscription && (
            <>
              <Loader />
              <p style={{ fontWeight: 'bold', fontSize: 18 }}>
                {t('Cancelling your subscription')}
              </p>
            </>
          )}
          {cancelledSubscription && (
            <>
              <IconWrapper height="100px" width="100px">
                <HeartEmojiSvg />
              </IconWrapper>
              <p style={{ fontWeight: 'bold', fontSize: 18 }}>
                {t(
                  'Subscription successfully cancelled! You still have access to your paid plan until the current period ends.',
                )}
              </p>
            </>
          )}
        </CenteredContainer>
      )}
    </BaseModal>
  );

  return {
    promptPaywall: prompt,
    hidePaywall: hide,
    paywallComponent: component,
    shouldPromptPaywall,
    isReady,
    isShowingPaywallPrompt: show,
    tenantPlan,
    tenantName: tenant?.tenantName,
  };
}

export type CheckoutState = {
  screen:
    | 'paywall'
    | 'chooseProduct'
    | 'chooseBillingCycle'
    | 'checkout'
    | 'downgradeToFree'
    | 'upgradeToEnterprise'
    | 'upgradeToSchoolSite'
    | 'previewSubscriptionChange'
    | 'freePremiumClaimed'
    | 'subscriptionChanged';
  product?: 'premium';
  billingCycle?: 'yearly' | 'monthly';
  priceId?: string;
  promoCode?: string;
  quantity?: number;
  country?: string;
};

export default usePaywallPrompt;
